<template>
  <div class="add">
    <el-container>
      <el-main style="background: #ffffff">
        <head-layout
          @head-save="headSave"
          @head-complete="headComplete"
          @head-cancel="headCancel"
          :head-btn-options="
            type == 'detail' ? headBtnOptionsDetail : headBtnOptions
          "
          head-title="风险信息"
        >
        </head-layout>
        <div style="padding: 12px">
          <el-form
            ref="form"
            :model="ruleForm"
            :rules="rules"
            label-width="160px"
            :disabled="type == 'detail'"
          >
            <el-row>
              <el-col :span="7">
                <el-form-item label="风险编号:" prop="riskCode">
                  <el-input
                    type="text"
                    v-model="ruleForm.riskCode"
                    placeholder="请输入风险编号"
                    maxlength="64"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item label="自动生成:">
                  <el-switch
                    @change="handleSwitch"
                    v-model="getRiskCode"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                  >
                  </el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="风险名称:" prop="riskName">
                  <el-input
                    type="text"
                    v-model="ruleForm.riskName"
                    placeholder="请输入风险名称"
                    maxlength="64"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="风险源:" prop="riskSource">
                  <el-select
                    v-model="ruleForm.riskSource"
                    @change="handleSource"
                    placeholder="请选择风险源"
                    value-key="id"
                    clearable
                  >
                    <el-option
                      v-for="item in riskSourceList"
                      :label="item.sourceName"
                      :value="item"
                      :key="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="17">
                <el-form-item label="行为事件:" prop="event">
                  <el-input
                    type="input"
                    v-model="ruleForm.event"
                    placeholder="请输入行为事件"
                    maxlength="64"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item prop="deptIdList" label="责任部门">
                  <!-- <depSelect
                    v-model="ruleForm.responsibleDep"
                    placeholder="请选择责任部门"
                    clearable
                    @change="handleDepSelect"
                  /> -->
                  <el-input
                    v-model="ruleForm.responsibleDep"
                    @focus="handleDeptModeOpenForm()"
                    placeholder="请选择责任部门"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="发生可能性:">
                  <el-select
                    v-model="ruleForm.lecdL"
                    placeholder="请选择发生可能性"
                    @change="handleChangeL"
                  >
                    <el-option
                      v-for="item in L"
                      :label="item.definition"
                      :value="item.id"
                      :key="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item label=""> </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="人员暴露风险环境繁度:">
                  <el-select
                    v-model="ruleForm.lecdE"
                    placeholder="请选择人员暴露风险环境繁度"
                    @change="handleChangeE"
                  >
                    <el-option
                      v-for="item in E"
                      :label="item.definition"
                      :value="item.id"
                      :key="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="可能后果:">
                  <el-select
                    v-model="ruleForm.lecdC"
                    @change="handleChangeC"
                    placeholder="请选择可能后果"
                  >
                    <el-option
                      v-for="item in C"
                      :label="item.definition"
                      :value="item.id"
                      :key="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="后果影响描述:" prop="consequence">
                  <el-input
                    type="textarea"
                    placeholder="请输入内容"
                    v-model="ruleForm.consequence"
                    maxlength="64"
                    show-word-limit
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="L:">
                  <el-input-number
                    v-model="ruleForm.lecdLScore"
                    @input="handleChange"
                    :min="0"
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="E:">
                  <el-input-number
                    v-model="ruleForm.lecdEScore"
                    @input="handleChange"
                    :min="0"
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="C:">
                  <el-input-number
                    v-model="ruleForm.lecdCScore"
                    @input="handleChange"
                    :min="0"
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="5" class="class-D">
                <el-form-item label="D:">
                  <el-input
                    style="width: calc(100% - 30px)"
                    v-model="ruleForm.lecdDScore"
                    :min="0"
                    default="0"
                    :disabled="true"
                  ></el-input>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="LEC风险评价法：L（Likelihood，事故发生的可能性）、E（Exposure，人员暴露于危险环境中的频繁程度）、C（Consequence，一旦发生事故可能造成的后果）。给三种因素的不同等级分别确定不同的分值，再以三个分值的乘积D（danger，危险性）来评价风险的大小"
                    placement="right-start"
                  >
                    <i
                      class="el-icon-question"
                      style="margin-left: 10px; font-size: 20px"
                    ></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="风险等级:">
                  <el-input
                    v-model="ruleForm.riskLevel"
                    placeholder="系统自动计算"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-main>
    </el-container>
    <el-container
      style="margin-top: 10px; height: calc(100% - 360px)"
      v-show="this.nowRuleFormId"
    >
      <CommonTree
        treeTitle="措施分类"
        :defaultProps="defaultProps"
        :isShowdig="type == 'detail' ? false : true"
        :searchTitle="searchTitle"
        :showCheckbox="false"
        :treeData="treeData"
        :treeExpand="true"
        style="font-size: 13px"
        @getNodeClick="handleNodeClick"
        @getTreeAdd="getTreeAdd"
        @getTreeDelete="getTreeDelete"
        @getTreeEdit="getTreeEdit"
      />
      <el-main>
        <head-layout
          :head-btn-options="type == 'detail' ? [] : subTableheadBtnOptions"
          head-title="应对措施"
          @head-delete-tabs="headRemove"
          @head-add-tabs="subTableAdd"
        ></head-layout>
        <grid-layout
          ref="crud"
          class="serviceType"
          :gridRowBtn="type == 'detail' ? [] : gridRowBtn"
          :table-options="subTableOption"
          :table-data="riskTreatmentList"
          :table-loading="tableLoading"
          :data-total="tableTotal"
          :page="page"
          @gird-handle-select-click="selectionChange"
          @page-current-change="handleCurrentChange"
          @page-size-change="handleSizeChange"
          @page-refresh-change="onLoad"
          @row-delete="rowRemove"
          @row-edit="treeMenuEdit"
        >
          <template slot="controlLevel" slot-scope="{ row }">
            <div>{{ controlLevelMap[row.controlLevel] || "" }}</div>
          </template>
          <template slot="respPostName" slot-scope="{ row }">
            <div>{{ row.respPostName }}/{{ row.respRoleName }}</div>
          </template>
        </grid-layout>
      </el-main>
    </el-container>
    <el-dialog
      v-dialog-drag
      title="新增应对措施分类"
      :modal="false"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showTreeDialog"
      v-if="showTreeDialog"
      width="60%"
      :fullscreen="fullscreen"
      class="qmDialog"
    >
      <div class="positionabs">
        <i class="el-icon-full-screen" @click="fullClick()"></i>
      </div>
      <avue-form
        :option="treeDialogOption"
        ref="treeForm"
        v-model="treeForm"
        @submit="treeNodeSave"
      ></avue-form>
      <span slot="footer">
        <el-button
          size="small"
          @click="showTreeDialog = false"
          v-loading="treeDialogLoading"
        >
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="handleTreeNodeSave"
          v-loading="treeDialogLoading"
        >
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      v-dialog-drag
      title="新增应对措施"
      :modal="false"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showTreeListDialog"
      v-if="showTreeListDialog"
      width="60%"
      :fullscreen="fullscreen"
      class="qmDialog"
    >
      <div class="positionabs">
        <i class="el-icon-full-screen" @click="fullClick()"></i>
      </div>
      <el-form :model="listForm" :rules="listRules" ref="listForm">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="措施分类" prop="treatmentTypeId">
              <el-select
                v-model="listForm.treatmentTypeId"
                clearable
                ref="selectTree"
                @clear="clearHandle"
              >
                <el-option
                  :value="listForm.treatmentTypeId"
                  :label="listForm.treatmentTypeName"
                >
                  <el-tree
                    id="tree-option"
                    :data="selectOptions"
                    :highlight-current="true"
                    :props="defaultProps"
                    :accordion="true"
                    node-key="id"
                    :default-expanded-keys="defaultExpandedKey"
                    @node-click="handleNodeClickListTree"
                  >
                  </el-tree>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="隐患管控层级">
              <el-select v-model="listForm.controlLevel">
                <el-option
                  v-for="item in controlLevelArr"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="措施描述" prop="treatmentDesc">
              <el-input
                type="textarea"
                :rows="2"
                maxlength="100"
                show-word-limit
                v-model="listForm.treatmentDesc"
                placeholder="请输入措施描述"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="隐患排查内容">
              <el-input
                type="textarea"
                :rows="2"
                maxlength="100"
                show-word-limit
                v-model="listForm.hdCheckContext"
                placeholder="请输入隐患排查内容"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="负责人" prop="respUserId">
              <!-- <userSelect
                v-model="listForm.respUserId"
                placeholder="请选择人员"
                @change="onChangePerson(arguments, item)"
                clearable
              /> -->
              <el-input
                v-model="listForm.respUserName"
                @focus="handleUserModeOpen()"
                placeholder="请选择人员"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="责任部门" prop="respDeptId">
              <!-- <depSelect
                v-model="listForm.respDeptId"
                placeholder="请选择责任部门"
                clearable
              /> -->
              <el-input
                v-model="listForm.respName"
                @focus="handleDeptModeOpen()"
                placeholder="请选择责任部门"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="责任人角色" prop="respRoleId">
              <roleSelect
                v-model="listForm.respRoleId"
                @change="changeRespRole"
                placeholder="请选择"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="责任人岗位" prop="respPostId">
              <posSelect
                v-model="listForm.respPostId"
                @change="changeRespPost"
                placeholder="请选择责任岗位"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button
          size="small"
          @click="showTreeListDialog = false"
          v-loading="listDialogLoading"
        >
          取消
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="handleListNodeSave"
          v-loading="listDialogLoading"
        >
          保存
        </el-button>
      </span>
    </el-dialog>
    <el-dialog title="人员选择" :visible.sync="personShow" width="80%" top="8vh">
      <UserDetpDialog
        ref="UserDetpDialog"
        @select-data="selectData"
      ></UserDetpDialog>
    </el-dialog>
    <el-dialog title="部门选择" :visible.sync="deptShow" width="80%">
      <DeptDialog ref="DeptDialog" @select-data="selectData1"></DeptDialog>
    </el-dialog>
    <el-dialog title="部门选择" :visible.sync="deptShowForm" width="80%">
      <DeptDialog ref="DeptDialog" @select-data="selectData2"></DeptDialog>
    </el-dialog>
  </div>
</template>

<script>
import CommonTree from "@/views/components/com_tree/index";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import { selectChildren } from "@/api/system/dept";
import { mapGetters } from "vuex";
import { getDictionaryCode } from "@/api/system/dictbiz";
import {
  getbsRiskLecdLMap,
  getbsrisklecdeMap,
  getbsRiskLecdCMap,
  getbsrisklecddlevelMap,
} from "@/api/setting/riskAssessment/index";
import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog";
import DeptDialog from "@/views/components/UserDeptDialog/DeptDialog";

import {
  getTree,
  addTree,
  deleteTree,
  getPage,
  addPage,
  deletePage,
  PageDetail,
  getPageCode,
  getRisksourceList,
  getTreatmentTree, //获取措施分类树
  addTreatmentTree, // 新增措施分类
  deleteTreatmentTree, // 删除措施分类
  getRisktreatmentPage, // 措施列表
  addRisktreatmentPage, // 新增措施
  getRisktreatmentDetail, // 措施详情
  deleteRisktreatmentPage, // 删除措施
  getRiskTreatmentTypeDetail, //措施分类详情
} from "@/api/riskManage/manage";
export default {
  components: {
    CommonTree,
    GridLayout,
    HeadLayout,
    UserDetpDialog,
    DeptDialog,
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      deptShow: false,
      deptShowForm: false,
      personShow: false,
      riskTreatmentList: [],
      showTreeDialog: false,
      showTreeListDialog: false,
      treeForm: {},
      dataArray: [],
      defaultExpandedKey: [],
      rules: {
        riskCode: {
          required: true,
          message: "请输入或自动生成编码",
          trigger: "blur",
        },
        riskName: {
          required: true,
          message: "请输入风险名称",
          trigger: "blur",
        },
        riskSource: {
          required: true,
          message: "请选择风险源",
          trigger: "blur",
        },
        consequence: {
          required: true,
          message: "请输入后果影响描述",
          trigger: "blur",
        },
        event: {
          required: true,
          message: "请输入行为事件",
          trigger: "blur",
        },
      },
      listRules: {
        treatmentTypeId: {
          required: true,
          message: "请选择措施分类",
          trigger: "blur",
        },
        treatmentDesc: {
          required: true,
          message: "请输入措施描述",
          trigger: "blur",
        },
      },
      listForm: {
        resp_post_code: "",
        resp_role_code: "",
        resp_dept_code: "",
        resp_user_id: "",
        treatmentDesc: "",
        controlLevel: "",
        treatmentTypeId: "",
      },
      selectOptions: [],
      controlLevelArr: [],
      treeDialogOption: {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: "上级措施分类",
            prop: "parentId",
            type: "tree",
            dicData: [],
            span: 12,
            props: {
              label: "treatmentTypeName",
              value: "id",
            },
          },
          {
            label: "措施分类名称",
            prop: "treatmentTypeName",
            type: "input",
            maxlength: 100,
            overHidden: true,
            span: 12,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入名称",
                trigger: "blur",
              },
            ],
          },
        ],
      },
      form: {},
      deptTreeData: [],
      defaultProps: {
        label: "treatmentTypeName",
        value: "id",
      },
      searchTitle: "treatmentTypeName",
      ruleForm: {},
      treeData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      tableData: [],
      subTableheadBtnOptions: [
        {
          label: "新增",
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: "add",
        },
        {
          label: "删除",
          emit: "head-delete-tabs",
          type: "button",
          icon: "",
          btnOptType: "delete",
        },
      ],
      headBtnOptions: [
        {
          label: "保存",
          emit: "head-save",
          type: "button",
          icon: "",
          btnOptType: "save",
        },
        {
          label: "完成",
          emit: "head-complete",
          type: "button",
          icon: "",
          btnOptType: "complete",
        },
        {
          label: "取消",
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: "cancel",
        },
      ],
      headBtnOptionsDetail: [
        {
          label: "取消",
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: "cancel",
        },
      ],
      gridRowBtn: [
        {
          label: "编辑",
          emit: "row-edit",
          type: "button",
          icon: "",
          btnOptType: "row-edit",
        },
        {
          label: "删除",
          emit: "row-delete",
          type: "button",
          icon: "",
          btnOptType: "row-delete",
        },
      ],
      subTableOption: {
        customRowAddBtn: false,
        menuFixed: "right",
        menuWidth: 100,
        showTooltip: true,
        indexLabel: "序号",
        index: true,
        column: [
          {
            label: "措施描述",
            prop: "treatmentDesc",
            align: "center",
            overHidden: true,
          },
          {
            label: "隐患排查内容",
            prop: "hdCheckContext",
            align: "center",
            overHidden: true,
          },
          {
            label: "管控层级",
            prop: "controlLevel",
            align: "center",
            overHidden: true,
            slot: true,
          },
          {
            label: "岗位/角色",
            prop: "respPostName",
            align: "center",
            overHidden: true,
            slot: true,
          },
          {
            label: "负责人员",
            prop: "respUserName",
            align: "center",
            overHidden: true,
          },
        ],
      },
      L: [],
      E: [],
      C: [],
      D: [],
      riskSourceList: [],
      getRiskCode: true,
      risktreatmentId: "",
      nowRuleFormId: "",
      type: "add",
      ids: "",
      nowNodeTree: {},
      tableTotal: 0,
      controlLevelMap: {},
    };
  },
  created() {
    this.getPageCode();
    this.getRisksourceList();
    this.getbsRiskLecdLMap();
    this.getbsrisklecdeMap();
    this.getbsRiskLecdCMap();
    this.getbsrisklecddlevelMap();
    this.getTreeData();
    this.getDictionaryCode();

    if (this.$route.query.nowRuleFormId) {
      this.nowRuleFormId = this.$route.query.nowRuleFormId;
      this.getPageDetail();
      this.getRisktreatmentPage();
      this.getdemotree();
    }
    this.type = this.$route.query.type;
    if (this.type == "add") {
      this.ruleForm.responsibleDepId = this.userInfo.dept_id;
      this.ruleForm.responsibleDep = this.userInfo.deptName;
    }
  },
  methods: {
    changeRespRole(id, data) {
      console.log(data, "changerespRole");
      this.listForm.respRoleName = data.roleName;
    },
    changeRespPost(id, data) {
      console.log(data, "changeRespPost");
      this.listForm.respPostName = data.postName;
    },
    selectData(row) {
      this.personShow = false;
      this.listForm.respUserId = row.id;
      this.listForm.respUserAccount = row.account;
      this.listForm.respUserName = row.realName;
      this.listForm.respNickName = row.nickName;
    },
    selectData1(row) {
      this.deptShow = false;
      this.listForm.respDeptId = row.id;
      this.listForm.respName = row.deptName;
    },
    selectData2(row) {
      this.deptShowForm = false;
      this.ruleForm.responsibleDepId = row.id;
      this.ruleForm.responsibleDep = row.deptName;
    },
    handleUserModeOpen() {
      this.personShow = true;
    },
    handleDeptModeOpen() {
      this.deptShow = true;
    },
    handleDeptModeOpenForm() {
      this.deptShowForm = true;
    },
    getPageDetail() {
      PageDetail({
        id: this.$route.query.nowRuleFormId,
        // organizationId: this.$route.query.organizationId
        //   ? this.$route.query.organizationId
        //   : this.userInfo.dept_id,
      }).then((res) => {
        this.ruleForm = res.data.data;
        this.ruleForm.riskSource = {
          id: this.ruleForm.sourceId,
          sourceName: this.ruleForm.sourceName,
        };
        this.computedriskLevel();
      });
    },
    // // 管控层级映射
    // getcontrolLevel(key) {
    //   const result = this.controlLevelArr.find((item) => {
    //     return item.dictKey == key;
    //   });
    //   return result.dictValue || "";
    // },
    // 计算风险等级
    computedriskLevel() {
      window.D = this.ruleForm.lecdDScore;
      const level = this.D.find((item) => {
        if (window.eval(item.formula)) {
          return item;
        }
      });
      if (level) {
        this.ruleForm.riskLevel = level.levelName;
        this.ruleForm.lecdColor = level.color;
        this.ruleForm.lecdD = level.levelCode;
      }
      return level || {};
    },
    // 计算D值
    computedlecdDScore() {
      this.ruleForm.lecdDScore =
        this.ruleForm.lecdLScore *
          this.ruleForm.lecdEScore *
          this.ruleForm.lecdCScore || 0;
      this.computedriskLevel();
    },
    handleChange() {
      this.computedlecdDScore();
    },
    handleChangeL(e) {
      const result = this.L.find((item) => item.id == e);
      this.ruleForm.lecdL = result.definition;
      this.ruleForm.lecdLScore = result.score;
      this.computedlecdDScore();
    },
    handleChangeE(e) {
      const result = this.E.find((item) => item.id == e);
      this.ruleForm.lecdE = result.definition;
      this.ruleForm.lecdEScore = result.score;
      this.computedlecdDScore();
    },
    handleChangeC(e) {
      const result = this.C.find((item) => item.id == e);
      this.ruleForm.lecdC = result.definition;
      this.ruleForm.lecdCScore = result.score;
      this.computedlecdDScore();
    },
    // 获取L映射
    getbsRiskLecdLMap() {
      // createDept: this.userInfo.dept_id
      getbsRiskLecdLMap({}).then((res) => {
        this.L = res.data.data;
      });
    },
    // 获取E映射
    getbsrisklecdeMap() {
      // createDept: this.userInfo.dept_id
      getbsrisklecdeMap({}).then((res) => {
        this.E = res.data.data;
      });
    },
    // 获取C映射
    getbsRiskLecdCMap() {
      // createDept: this.userInfo.dept_id
      getbsRiskLecdCMap({}).then((res) => {
        this.C = res.data.data;
      });
    },
    // 获取D映射
    getbsrisklecddlevelMap() {
      //  createDept: this.userInfo.dept_id
      getbsrisklecddlevelMap({}).then((res) => {
        this.D = res.data.data;
      });
    },
    // 获取风险源映射
    getRisksourceList() {
      getRisksourceList({ organizationId: this.userInfo.dept_id }).then(
        (res) => {
          this.riskSourceList = res.data.data;
          if (this.$route.query.riskSource) {
            const result = this.riskSourceList.find((item) => {
              return item.id == this.$route.query.riskSource;
            });
            this.ruleForm.riskSource = result;
          }
        }
      );
    },
    // 获取隐患管控层级下拉
    getDictionaryCode() {
      getDictionaryCode("risk_control_level").then((res) => {
        this.controlLevelArr = res.data.data;
        this.controlLevelArr.map((item) => {
          this.controlLevelMap[item.dictKey] = item.dictValue;
        });
      });
    },
    getTreeData() {
      selectChildren().then((res) => {
        if (res.data.code == 200) {
          this.deptTreeData = res.data.data;
        }
      });
    },
    // 获取措施分类树
    getdemotree() {
      getTreatmentTree({
        organizationId: this.userInfo.dept_id,
        createDept: this.userInfo.dept_id,
        riskId: this.nowRuleFormId,
      }).then((res) => {
        if (res.status == 200) {
          this.treeData = res.data.data;
          this.treeDialogOption.column[0].dicData = res.data.data;
          this.selectOptions = res.data.data;
        }
      });
    },
    getRiskTreatmentTypeDetail() {
      getRiskTreatmentTypeDetail({ id: this.nowNodeId }).then((res) => {
        this.treeForm = res.data.data;
      });
    },
    // 新增措施分类
    getTreeAdd() {
      if (this.type == "detail") {
        this.$message({
          message: "查看详情不可操作",
          type: "warning",
        });
        return;
      }

      this.treeForm = {};
      if (this.nowNodeId) {
        this.treeForm.parentId = this.nowNodeId;
      }
      this.showTreeDialog = true;
    },
    handleTreeNodeSave() {
      // 新增树节点
      this.$refs.treeForm.validate((valid) => {
        if (valid) {
          addTreatmentTree({
            ...this.treeForm,
            createDept: this.userInfo.dept_id,
            organizationId: this.userInfo.dept_id,
            parentId: this.treeForm.parentId || -1,
            riskId: this.nowRuleFormId,
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: "保存成功",
                type: "success",
              });
              this.showTreeDialog = false;
              this.getdemotree();
            }
          });
        }
      });
    },
    // 编辑
    getTreeEdit() {
      if (this.type == "detail") {
        this.$message({
          message: "查看详情不可操作",
          type: "warning",
        });
        return;
      }
      this.treeForm = {};
      if (!this.nowNodeId) {
        this.$message({
          message: "请选中一个节点进行编辑",
          type: "warning",
        });
        return;
      }
      this.getRiskTreatmentTypeDetail();
      this.showTreeDialog = true;
    },
    // 删除
    getTreeDelete() {
      if (this.type == "detail") {
        this.$message({
          message: "查看详情不可操作",
          type: "warning",
        });
        return;
      }
      if (this.nowNodeId) {
        this.$confirm("确认删除当前选中节点吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            deleteTreatmentTree({ ids: this.nowNodeId }).then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.getdemotree();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else {
        this.$message({
          message: "请选择一个节点",
          type: "warning",
        });
      }
    },
    //点击树节点显示列表
    handleNodeClick(data) {
      this.nowNodeId = data.id;
      this.nowNodeTree = data;
      if (!this.nowRuleFormId) {
        return;
      }
      let params = {
        size: this.page.pageSize,
        current: this.page.currentPage,
        treatmentTypeId: this.nowNodeId,
        organizationId: this.userInfo.dept_id,
        createDept: this.userInfo.dept_id,
        riskId: this.nowRuleFormId,
      };
      // 调用列表接口;
      getRisktreatmentPage(params).then((res) => {
        if (res.data.code == 200) {
          this.riskTreatmentList = res.data.data.records;
          this.tableTotal = res.data.data.total;
          this.$nextTick(() => {
            this.$refs.crud.$refs.grid.$refs.table.doLayout();
          });
        }
      });
    },
    getRisktreatmentPage() {
      let params = {
        size: this.page.pageSize,
        current: this.page.currentPage,
        organizationId: this.userInfo.dept_id,
        createDept: this.userInfo.dept_id,
        riskId: this.nowRuleFormId,
      };
      // 调用列表接口;
      getRisktreatmentPage(params).then((res) => {
        if (res.data.code == 200) {
          this.riskTreatmentList = res.data.data.records;
          this.tableTotal = res.data.data.total;
        }
      });
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize;
      this.getRisktreatmentPage();
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.getRisktreatmentPage();
    },

    handleSwitch() {
      if (this.getRiskCode) {
        this.getPageCode();
      } else {
        this.ruleForm.riskCode = "";
      }
    },
    getPageCode() {
      // 获取编码
      getPageCode({}).then((res) => {
        this.$set(this.ruleForm, "riskCode", res.data.data || "");
        this.$forceUpdate();
      });
    },
    handleCheckChange() {
      let checkedKeys = this.$refs.deptTree.getCheckedKeys();
      this.form.deptIdList = checkedKeys;
    },
    handleDepSelect(e) {},
    rowDel(row) {
      this.DelLsitid = row.id;
      this.dialogVisible3 = true;
    },
    handleSource(e) {
      this.ruleForm.sourceCode = this.ruleForm.riskSource.sourceCode;
      this.ruleForm.sourceId = this.ruleForm.riskSource.id;
      this.ruleForm.sourceName = this.ruleForm.riskSource.sourceName;
      this.ruleForm.sourceTypeId = this.ruleForm.riskSource.sourceTypeId;
    },
    // 完成
    headComplete() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (!this.ruleForm.sourceId) {
            this.ruleForm.sourceCode = this.ruleForm.riskSource.sourceCode;
            this.ruleForm.sourceId = this.ruleForm.riskSource.id;
            this.ruleForm.sourceName = this.ruleForm.riskSource.sourceName;
            this.ruleForm.sourceTypeId = this.ruleForm.riskSource.sourceTypeId;
          }
          let parmas = {
            ...this.ruleForm,
            libraryStatus: "FINISHED",
            organizationId: this.userInfo.dept_id,
            createDept: this.userInfo.dept_id,
          };
          if (this.nowRuleFormId) {
            parmas.id = this.nowRuleFormId;
          }
          addPage(parmas).then((res) => {
            if (res.data.code == 200) {
              this.nowRuleFormId = res.data.data;
              this.$message({
                message: "保存成功",
                type: "success",
              });

              this.getdemotree();
              this.getRisktreatmentPage();
            }
          });
        }
      });
      this.$set(this, "treeData", this.treeData);
    },
    // 取消
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    // 保存
    headSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (!this.ruleForm.sourceId) {
            this.ruleForm.sourceCode = this.ruleForm.riskSource.sourceCode;
            this.ruleForm.sourceId = this.ruleForm.riskSource.id;
            this.ruleForm.sourceName = this.ruleForm.riskSource.sourceName;
            this.ruleForm.sourceTypeId = this.ruleForm.riskSource.sourceTypeId;
          }
          let parmas = {
            ...this.ruleForm,
            libraryStatus: "PREPARE",
            organizationId: this.userInfo.dept_id,
            createDept: this.userInfo.dept_id,
          };
          if (this.nowRuleFormId) {
            parmas.id = this.nowRuleFormId;
          }
          addPage(parmas).then((res) => {
            if (res.data.code == 200) {
              this.nowRuleFormId = res.data.data;
              this.$message({
                message: "保存草稿成功",
                type: "success",
              });
              this.getRisktreatmentPage();
              this.getdemotree();
            }
          });
        }
      });
      this.$set(this, "treeData", this.treeData);
    },
    // 编辑
    treeMenuEdit(e, index) {
      this.listForm = e;
      this.showTreeListDialog = true;
    },
    headRemove() {
      console.log(this.ids);
      if (!this.ids) {
        this.$message({
          message: "请勾选数据",
          type: "warning",
        });
        return;
      }
      this.$confirm("确认删除当前选中数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteRisktreatmentPage({ ids: this.ids }).then((res) => {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getRisktreatmentPage();
          });
        })
        .catch((error) => {
          this.$message({
            message: "取消删除",
            type: "success",
          });
        });
    },
    rowRemove(row) {
      this.$confirm("确认删除当前选中数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteRisktreatmentPage({ ids: row.id }).then((res) => {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getRisktreatmentPage();
          });
        })
        .catch((error) => {
          this.$message({
            message: "取消删除",
            type: "success",
          });
        });
    },
    // 新增措施列表
    subTableAdd() {
      this.listForm = {};
      if (this.nowNodeId) {
        this.listForm.treatmentTypeName = this.nowNodeTree["treatmentTypeName"];
        this.listForm.treatmentTypeId = this.nowNodeTree["id"];
      }

      this.showTreeListDialog = true;
    },
    selectionChange(arr) {
      this.ids = arr
        .map((item) => {
          return item.id;
        })
        .join(",");
    },
    handleNodeClickListTree(node, obj) {
      this.listForm.treatmentTypeName = node["treatmentTypeName"];
      this.listForm.treatmentTypeId = node["id"];
      this.defaultExpandedKey = [];
      this.$refs.selectTree.blur();
    },
    clearHandle() {
      this.listForm.treatmentTypeName = "";
      this.listForm.treatmentTypeId = "";
      this.defaultExpandedKey = [];
      this.clearSelected();
    },
    clearSelected() {
      let allNode = document.querySelectorAll("#tree-option .el-tree-node");
      allNode.forEach((element) => element.classList.remove("is-current"));
    },
    // 保存措施
    handleListNodeSave() {
      this.$refs.listForm.validate((valid) => {
        if (valid) {
          addRisktreatmentPage({
            ...this.listForm,
            riskId: this.nowRuleFormId,
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: "保存成功",
                type: "success",
              });
            }
            this.showTreeListDialog = false;
            let params = {
              size: this.page.pageSize,
              current: this.page.currentPage,
              treatmentTypeId: this.nowNodeId,
              organizationId: this.userInfo.dept_id,
              createDept: this.userInfo.dept_id,
              riskId: this.nowRuleFormId,
            };
            // 调用列表接口;
            getRisktreatmentPage(params).then((res) => {
              if (res.data.code == 200) {
                this.riskTreatmentList = res.data.data.records;
                this.tableTotal = res.data.data.total;
                this.$nextTick(() => {
                  this.$refs.crud.$refs.grid.$refs.table.doLayout();
                });
              }
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.add {
  height: 100%;
}
::v-deep .common_tree_handle {
  height: 100%;
  .el-tree {
    height: calc(100% - 112px) !important;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
::v-deep #gridLayout {
  height: calc(100% - 90px) !important;
}
::v-deep .avue-crud {
  height: 100% !important;
  .el-card {
    height: 100% !important;
  }
  .el-card__body {
    height: 100% !important;
    .el-form {
      height: 100% !important;
      .el-table {
        height: 100% !important;
      }
    }
  }
  .el-table__empty-text {
    height: 100% !important;
  }
}
.el-main {
  height: 100%;
  overflow: hidden;
}
</style>
